// Sleeps for the given amount of time
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
// Indicates that this Promise doesn't need be awaited
// Use this instead of a trailing empty `.then()`.
export function unawaited(promise) {
    promise === null || promise === void 0 ? void 0 : promise.then();
}
export async function waitForFrame() {
    return new Promise((resolve) => requestAnimationFrame(() => requestAnimationFrame(resolve)));
}
